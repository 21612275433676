<template>
  <el-dialog
    append-to-body
    width="600px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >

    <!-- <el-descriptions class="margin-top" title="" :column="3"  border>
      <el-descriptions-item label="分类"></el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        18100000000
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          居住地
        </template>
        苏州市
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          备注
        </template>
        <el-tag size="small">学校</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          联系地址
        </template>
        江苏省苏州市吴中区吴中大道 1188 号
      </el-descriptions-item>
    </el-descriptions> -->


    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      
      <el-form-item prop='type_str' label='分类'>
        <el-input v-model="form.data.title" :readonly="true"></el-input>
      </el-form-item>
      <el-form-item prop='title' label='标题'>
        <el-input v-model="form.data.title" :readonly="true"></el-input>
      </el-form-item>
      <el-form-item prop='content' label='说明' class="textareaBox">
        <el-input
          type="textarea"
          v-model="form.data.content"
          show-word-limit
          :autosize="{ minRows: 4, maxRows: 10}"
          :readonly="true"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop='video' label='图片/视频' v-if="!!form.data.video">
        <video v-if="imgType == 'video'" :src="form.data.video" controls height="130px" width="230px"></video>
        <el-image v-else :src="form.data.video" class="avatar" :preview-src-list="[form.data.video]" ></el-image>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary"  @click='toggle(false)'>关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'GuideDetail',
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            type: '',
            title: '',
            content: '',
            role_type: '',
            video: '',
          },
          rules: {
            type: [{ required: true, message:'请选择分类', trigger: 'change' }],
            title: [{ required: true, message:'请输入标题', trigger: 'change' }],
            content: [{ required: true, message: '请输入说明', trigger: 'change' }],
          }
        },
        fileList: [],
        imgType: '',
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('government/guide/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
            if(res.data.video.includes('.mp4') || res.data.video.includes('.mp3') ) {
              this.imgType = 'video'
            }else {
              this.imgType = ''
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>

<style scoped>
.textareaBox ::v-deep .el-input__count {
  color: #909399;
  background: none !important;
  position: absolute;
  font-size: 12px;
  bottom: 0;
  right: 10px;
}
.avatar-uploader-image .el-icon-plus {
  width: 230px;
  height: 130px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-image .avatar {
  width: 230px;
  height: 130px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 130px !important;
}
</style>
