<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.role_type" @change="handleTabs" style="margin-bottom:10px;">
      <el-radio-button :label="2">师傅端</el-radio-button>
      <el-radio-button :label="1">需求端</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="标题:" prop="keyword">
          <el-input clearable v-model="table.params.keyword" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select v-model="table.params.type" clearable placeholder="请选择类型">
            <el-option
              v-for="item in typeOpt"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="table.params.status" clearable placeholder="请选择状态">
            <el-option
              v-for="item in statusOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
      <el-button size="mini" type="primary" plain @click="handleExportBatch">批量删除</el-button> -->
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:index="{$index}">
        <span>{{$index+1}}</span>
      </template>
      
      <template v-slot:is_show="{row}">
        <span>{{row.is_show === 1 ? '显示' : '关闭'}}</span>
      </template>

      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>

    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'GuideList',
  components: {
    VTable,
    Detail,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "type", label: "类型", width:"140", hidden: false },
        { name: "title", label: "标题", showTooltip: true, hidden: false },
        { name: "content", label: "说明", showTooltip: true, hidden: false },
        { name: "create_time", label: "创建时间", width:"150",  hidden: false },
        { name: "is_show", label: "状态", width:"100", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "100", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          role_type: 2,
          keyword: '',
          type: '',
          status: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      typeOpt: [],
      statusOpt: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '显示'
        },
        {
          id: 2,
          name: '关闭'
        }
      ],
      delIds: [],
    }
  },
  created() {
    this.getBaseInfo();
    this.getTable();
  },
  methods: {
    // 获取指南分类
    getBaseInfo() {
      this.$http.get('common/constant/type', {params: {role_type: this.table.params.role_type}}).then(res => {
        if(res.code === 1) {
          this.typeOpt = res.data
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    async getTable() {
      this.$http.get('government/guide/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
      this.getBaseInfo();
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
  }
}
</script>